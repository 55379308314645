@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.logo {
    width: 148px;
    height: 56px;
}

.logo img {
    height: 100%;
    width: auto;
}

.top-header {
    background-color: black;
    height: 15px;
}

.header {
    z-index: 1;
    border-bottom: 1px solid #e8e8e8
}

.header__inner {
    padding: 16px 0;
}

.alert-mm-yellow {
    background-color: #fcaf25;
    border: #e49914 1px solid !important;
    color: #000;
}

.alert-mm-green {
    border: 1px solid #039124;
    background-color: #04a129;
    color: #fff;
}

.tick-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3Csvg fill='%23FFFFFF' height='800px' width='800px' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 191.667 191.667' xml:space='preserve'%3E%3Cpath d='M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z'/%3E%3C/svg%3E");
    background-size: cover;
}

.terms__container h3 {
    font-size: 1.25em;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol>li {
    margin: 0;
}

li ol>li:before {
    content: counters(item, ".") " ";
}

li ol>li ol {
    list-style-type: lower-alpha;
}

li ol>li ol li:before {
    content: counter(item, lower-alpha) ") ";
}

.footer {
    background: #333;
    color: #fff;
}

.footer strong {
    color: #fbaf25;
}

.footer .btn-success {
    background-color: #04a129;
}

.footer .btn-success:hover {
    background-color: #028521;
}